<template>
    <div>
        <div>
            <el-card class="box-card">
                <div v-if="haveAnswer" class="answer-style">
                    <p class="content"><span>标准答案:</span> <span v-html="questionBody.correct"></span></p>
                    <p class="content">
                        <span>通过率:</span>60%
                    </p>
                    <p class="content">
                        <span>得分率:</span>60%
                    </p>
                    <div style="display: flex;">
                        <p class="content"><span>难度:</span></p>
                        <el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
                    </div>
                    <p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            subjectId: {
                type: Number,
                default: 1
            },
            isDis: {
                type: Boolean,
                default: false
            },
            questionBody: {
                type: Object,
                default: () => ({})
            },
            haveAnswer: {
                type: Boolean,
                default: false
            },
            chooseItem: {
                type: String,
                default: ""
            }
        },
        mounted() {
            // console.log(this.questionBody)
            // console.log(this.haveAnswer)
            // if (this.haveAnswer) {
            // 	this.radio = this.questionBody.answerInfo.content
            // }
        },
        watch: {
            radio: {
                handler: function (n, o) {
                    if (this.haveAnswer) {

                    } else {
                        if (this.radio) {
                            // console.log(this.radio)
                            // console.log(this.questionBody.id)
                            this.$emit("changed", {
                                id: this.subjectId,
                                name: this.radio,
                                questionId: this.questionBody.id,
                                content: this.radio,
                                contentArray: [],
                                itemOrder: this.subjectId
                            })
                        } else {
                            this.$emit("unchanged", {
                                id: this.subjectId,
                                name: this.radio
                            })
                        }
                    }

                }
            }
        },
        data() {
            return {
                radio: "",
                value1: 4.5
            }
        }
    }
</script>

<style lang="scss" scoped>

    .answer-style{
        border: 1px solid #eee;
        border-radius: 5px;
        line-height: 24px;
        margin: 5px 0 10px 0;
        padding: 10px;
        font-size: 14px;
        color: #555;
        background: #fcfcfc;
    }

     ::v-deep .el-radio {
        display: flex;
        margin-top: 5px;
    }

    .subjectName {
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        p {
            font-size: 16px;
        }
    }


    .content {
        font-size: 16px;
        margin-top: 20px;
        //color: #808080;
        color: #606266;
        display: flex;

        span {
            font-weight: 500;
            color: #000;
            line-height: 20px;
        }
    }
</style>
